import { Injectable, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  isLoading: Subject<boolean> = new Subject<boolean>();
  fileReader = signal<FileReader>(new FileReader());

  show() {
    this.isLoading.next(true);
  }

  hide() {
    this.isLoading.next(false);
  }

  markFormFieldsAsDirty(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control?.markAsDirty();
    });
  }

  // can be moved to other file
  readFile(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      this.fileReader().onload = () => resolve(this.fileReader().result);
      this.fileReader().onerror = reject;
      this.fileReader().readAsDataURL(file);
    });
  }

  getFileSizeInMb(fileSize: number) {
    return `${(fileSize / (1024 * 1024)).toFixed(2)} MB`;
  }

}